import React, { useCallback, useMemo } from "react";
import { useUser } from "../../../contexts/Msal/useMsalUser";
import { IStoreProductRating } from "../../../models/product/types";

export const useProductRatings = (ratings?: Array<IStoreProductRating>) => {
  const { msUser } = useUser();
  const productRating = useMemo(() => {
    if (!ratings) {
      return;
    }
    if (ratings.length === 0) {
      return;
    }
    const total = ratings.reduce((currRating, r) => {
      return currRating + r.rating;
    }, 0);

    return total / ratings.length;
  }, [ratings]);

  const getUserRating = useCallback(() => {
    return ratings?.find((r) => r.email === msUser?.username);
  }, [ratings, msUser?.username]);

  return { productRating, getUserRating };
};
