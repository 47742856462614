import React from "react";
import { AiFillHome } from "react-icons/ai";
import { BiCart } from "react-icons/bi";
import { BsHeadset } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { ZONI_PORTAL_PAGES } from "../../../constants/links";
import { useUser } from "../../../contexts/Msal/useMsalUser";
import { useNavigator } from "../../../pages-lib/navigation/useNavigator";
import { openNewTab } from "../../../utils/openNewTab";
import { ProductSearchBar } from "../Search/ProductSearchBar";
import { SideMenuItem } from "./SideMenuItem";

export const SideMenuItemsUser = () => {
  const { navigate, isRouteActive } = useNavigator();

  const { msUser, dbUser } = useUser();

  return (
    <>
      <div className="p-sm">
        <ProductSearchBar />
      </div>

      <div className="py-2">
        <SideMenuItem
          icon={<AiFillHome />}
          label={"Home"}
          selected={isRouteActive("home")}
          onClick={() => navigate("home")}
        />
        <SideMenuItem
          icon={<BiCart />}
          label={"Cart"}
          selected={isRouteActive("cart")}
          onClick={() => navigate("cart")}
        />
        <SideMenuItem
          icon={<GoLocation />}
          label={"Find a Store "}
          selected={isRouteActive("findMyStore")}
          onClick={() => navigate("findMyStore")}
        />
        <SideMenuItem
          icon={<BsHeadset />}
          label={"Support "}
          selected={false}
          onClick={() =>
            openNewTab(
              ZONI_PORTAL_PAGES.CREATE_TICKET({
                email: msUser?.username ?? "",
                firstName: dbUser?.firstName ?? "",
                lastName: dbUser?.lastName ?? "",
                phone: dbUser?.phone ?? "",
              })
            )
          }
        />
      </div>
    </>
  );
};
