import React, { useCallback } from "react";
import { getProductFilesURL } from "../../../config/linkResolvers";

export const resolveProductImages = (files?: IStoreProductFile[]) => {};

export const useProductImages = () => {
  const primaryImage = useCallback((files?: IStoreProductFile[]) => {
    const primaryImageFile = files?.filter((f) => f.categ === "P");
    if (primaryImageFile && primaryImageFile?.length >= 1) {
      return (
        primaryImageFile[0]?.absoluteUrl ??
        getProductFilesURL({
          directory: primaryImageFile[0]?.subdir,
          fileNameWithExtension: primaryImageFile[0]?.fileName,
        })
      );
    }
  }, []);

  const secondaryImages = useCallback((files?: IStoreProductFile[]) => {
    const secondaryImageFiles = files?.filter((f) => f.categ === "S");
    if (Array.isArray(secondaryImageFiles) && secondaryImageFiles?.length > 0) {
      return secondaryImageFiles.map(
        (f) =>
          f?.absoluteUrl ??
          getProductFilesURL({
            directory: f?.subdir,
            fileNameWithExtension: f?.fileName,
          })
      );
    }
  }, []);

  return { primaryImage, secondaryImages };
};
