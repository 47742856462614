import { useRouter } from "next/router";
import { useCallback } from "react";
import { APP_ROUTES, IAppRoutesName } from "../../constants/appRoutes";

export const useNavigator = () => {
  const router = useRouter();
  const navigateToProductDetail = useCallback(
    (productId: string) => {
      router.push(`${APP_ROUTES.product.location}/${productId}`);
    },
    [router]
  );

  const navigateTemplateFn = <T extends IAppRoutesName>(
    routeName: T,
    queries?: Record<keyof typeof APP_ROUTES[T]["queries"], string>,
    slug?: Array<string>
  ) => {
    let dynamicSubPath = "";
    if (Array.isArray(slug) && slug.length > 0) {
      slug.forEach((i) => {
        dynamicSubPath += `/${i}`;
      });
    }
    if (!queries) {
      router.push({
        pathname: `${APP_ROUTES[routeName].location}${dynamicSubPath}`,
      });
      return;
    }
    router.push({
      pathname: `${APP_ROUTES[routeName].location}${dynamicSubPath}`,
      query: queries,
    });
  };

  const navigate = useCallback(navigateTemplateFn, [router]);

  const isRouteActive = useCallback(
    (routeName: IAppRoutesName) => {
      const currentPath = router.asPath;

      const currentRouteId = Object.values(APP_ROUTES).find(
        (i) => i.location === currentPath
      )?.id;

      if (currentRouteId === routeName) {
        return true;
      }
      return false;
    },
    [router.asPath]
  );

  return {
    navigateToProductDetail,
    navigate,
    isRouteActive,
  };
};
