import { Image } from "@mantine/core";
import Link from "next/link";
import React, { useMemo } from "react";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { APP_INFO, COPYRIGHT_TEXT } from "../../../constants/appInfo";
import { APP_ROUTES } from "../../../constants/appRoutes";
import { useStoreBranches } from "../../../hooks/useStoreBranches";
import { openNewTab } from "../../../utils/openNewTab";

export const Footer = () => {
  const { storeBranches } = useStoreBranches();

  const { email, phone } = useMemo(() => {
    const onlineStore = storeBranches?.find((i) => i.storeId === "O");
    return { phone: onlineStore?.phone, email: onlineStore?.email };
  }, [storeBranches]);

  return (
    <footer className=" bg-primary-accent text-white  z-40">
      <div className="  flex flex-col ">
        <div
          className="relative flex flex-col pl-4 md:pl-0 md:flex-row md:justify-center  md:items-start space-y-10 md:space-y-0 md:space-x-40 pb-60 py-8 bg-no-repeat bg-center"
          style={{ backgroundImage: `url(${"/bannerBgUS.svg"})` }}
        >
          <div className="flex flex-col">
            <div className="uppercase font-extralight ">About</div>
            <Link href={APP_ROUTES.aboutUs.location} passHref>
              <a className=""> About Us</a>
            </Link>

            <Link href={APP_ROUTES.findMyStore.location} passHref>
              <a className=""> Find a ZONI store</a>
            </Link>
          </div>

          <div className="flex flex-col ">
            <div className="uppercase font-extralight ">Resources</div>

            <Link href={APP_ROUTES.faqs.location} passHref>
              <a className=" ">FAQs</a>
            </Link>
            <Link href={APP_ROUTES.termsAndConditions.location} passHref>
              <a className=" ">Terms & Conditions</a>
            </Link>
            <Link href={APP_ROUTES.privacyPolicy.location} passHref>
              <a className="">Privacy Policy</a>
            </Link>
            <Link href={APP_ROUTES.returnPolicy.location} passHref>
              <a className=" ">Return Policy</a>
            </Link>
            <Link href={APP_ROUTES.return.location} passHref>
              <a className=" ">Shipping And Returns</a>
            </Link>
          </div>
          <div>
            <div className="uppercase font-extralight ">Contact Us</div>
            <div className="">{phone}</div>
            <div className="">{email}</div>
            <div className="flex  space-x-2 my-2">
              {" "}
              <BsFacebook
                className="text-xl"
                onClick={() => openNewTab(APP_INFO.ORG_FACEBOOK_URL)}
              />
              <BsInstagram
                className="text-xl"
                onClick={() => openNewTab(APP_INFO.ORG_INSTAGRAM_URL)}
              />
            </div>
          </div>
          <div className="hidden lg:block  max-w-[10rem] ">
            {" "}
            <Image src="/logoWithTextWhite.svg" alt={"logo"} />
          </div>
        </div>

        <div className="text-sm font-light text-center py-4 lg:py-6 bg-primary-red">
          {COPYRIGHT_TEXT}
        </div>
      </div>
    </footer>
  );
};
