import React from "react";

interface IBadgeNotificationProps {
  notificationCount?: number;
  exclaim?: boolean;
}

export const BadgeNotification: React.FC<IBadgeNotificationProps> = ({
  notificationCount,
  exclaim,
}) => {
  return (
    <>
      {(!!notificationCount || exclaim) && (
        <div className="bg-primary-red text-white rounded-full h-4 w-4 text-xs ">
          {typeof notificationCount === "number"
            ? notificationCount
            : exclaim
            ? "!"
            : ""}
        </div>
      )}
    </>
  );
};
