import { ActionIcon, Chip, Chips, Divider, Menu } from "@mantine/core";
import React, { useMemo } from "react";
import { GrLogout, GrHome } from "react-icons/gr";
import { BiStore, BiLogOut } from "react-icons/bi";
import { useUser } from "../../../contexts/Msal/useMsalUser";
import { IStoreRole } from "../../../models/session/createSession";
import { useNavigator } from "../../../pages-lib/navigation/useNavigator";
import { lowercase } from "../../../utils/lowercase";
import { ProfilePic } from "../../libs/ProfilePicture/ProfilePicture";
import { RoleIndicator } from "./RoleIndicator";
import { STORE_ROLES } from "../../../constants/roles";

interface IUserProfileProps {
  firstName?: string;
  lastName?: string;
  //   roles?: Array<IDepartmentCode>;
  profileUrl?: string;
  email?: string;
  role?: IStoreRole;
  onLogoutClick: () => void;
}

export const UserProfile: React.FC<IUserProfileProps> = ({
  email,
  firstName,
  lastName,
  profileUrl,
  role,
  onLogoutClick,
  //   roles,
}) => {
  const fullName = useMemo(() => {
    const _firstName = lowercase(firstName ?? "");
    const _lastName = lowercase(lastName ?? "");
    return `${_firstName} ${_lastName}`;
  }, [firstName, lastName]);
  //   const departments = useDepartments();

  const { navigate } = useNavigator();
  const { hasAccessToZoniStorePanel, dbUser } = useUser();

  return (
    <div className="flex space-x-2 text-xs items-center">
      <Menu
        control={
          <ActionIcon className=" w-10 h-10">
            <div className=" w-10 h-10 flex justify-center items-center rounded-full overflow-hidden">
              <ProfilePic title="pp" url={profileUrl} />
            </div>
          </ActionIcon>
        }
        className="h-full"
        trigger="hover"
      >
        <Menu.Item>
          <div className="flex flex-col space-y-2">
            <div className="capitalize">{fullName}</div>
            <div className="text-primary-light text-lighter">{email}</div>
          </div>
        </Menu.Item>
        <Divider />

        {/* <Menu.Label>Your Roles</Menu.Label>
        <Menu.Item>
          <div className="flex flex-wrap justify-center space-x-2">
            <RoleIndicator
              roleAbbr={role ?? ""}
              description={role}
              className={"bg-primary-success text-white"}
            />
          </div>
        </Menu.Item>

        {!!dbUser &&
          Array.isArray(dbUser?.roles) &&
          dbUser.roles.length > 0 && (
            <Menu.Item>
              <div className="flex flex-wrap justify-center space-x-2">
                {dbUser?.roles.map((i) => (
                  <RoleIndicator
                    key={i}
                    roleAbbr={STORE_ROLES[i]?.name}
                    description={STORE_ROLES[i]?.name}
                  />
                ))}
              </div>
            </Menu.Item>
          )}
        <Divider /> */}
        {hasAccessToZoniStorePanel && (
          <Menu.Item
            onClick={() => navigate("adminOrders")}
            icon={<BiStore className="text-lightest text-base" />}
          >
            Zoni Store Panel
          </Menu.Item>
        )}
        <Menu.Item
          onClick={onLogoutClick}
          icon={<BiLogOut className="text-lightest text-base" />}
        >
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
};
