import { Badge } from "@mantine/core";
import React from "react";

interface ISideMenuItemProps {
  label: React.ReactNode;
  icon: React.ReactNode;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
  show?: boolean;
  children?: React.ReactNode;
}

export const SideMenuItem: React.FC<ISideMenuItemProps> = ({
  icon,
  label,
  selected,
  onClick,
  disabled = false,
  show = true,
}) => {
  if (!show) {
    return null;
  }
  return (
    <div
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
      className={`
      ${disabled ? "opacity-60 cursor-not-allowed" : ""}
        ${selected ? "text-primary-accent " : "text-lighter "}
      } flex items-center space-x-4  p-sm cursor-pointer opacity-80 hover:opacity-100`}
    >
      <div className="flex-none  rounded-lg border w-8 h-8  flex justify-center items-center shadow-primary">
        {" "}
        {icon}
      </div>
      <div className="flex-1  text-sm overflow-hidden"> {label}</div>
      {!!disabled && (
        <Badge color={"gray"} variant="outline">
          {" "}
          Disabled
        </Badge>
      )}
    </div>
  );
};
