import { IProductsParams, IProductsResult } from "../pages/api/products";
import { fetcher } from "./fetcher";

interface IProductsGetParams extends IProductsParams {}

interface IProductsGetResult extends IProductsResult {}

export const productsGet = async (payload: IProductsGetParams) => {
  const res = await fetcher.fetch("/api/products", {
    method: "POST",
    body: JSON.stringify({
      ...payload,
    } as IProductsGetParams),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();
  if (!a.success) {
    throw new Error(JSON.stringify(a));
  } else {
    return a.data as IProductsGetResult;
  }
};
