import {
  IStoreBranchesParams,
  IStoreBranchesResult,
} from "../pages/api/storeBranches";
import { fetcher } from "./fetcher";

export const storeBranchesGet = async (params: IStoreBranchesParams) => {
  const res = await fetcher.fetch("/api/storeBranches", {
    method: "POST",
    body: JSON.stringify({ ...params }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  const a = await res.json();
  if (!a.success) {
    throw new Error(JSON.stringify(a));
  } else {
    return a.data as IStoreBranchesResult;
  }
};
