import React from "react";

interface ISideMenuProps {
  show: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const SideMenu: React.FC<ISideMenuProps> = ({
  show,
  className,
  children,
}) => {
  return (
    <>
      {show && (
        <div className={`${className ? className : ""}  h-screen bg-white`}>
          {children}
        </div>
      )}
    </>
  );
};
