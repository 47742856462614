import React from "react";
import { SideMenu } from "./SideMenu";
import { useBoolean } from "react-use";
import { Drawer } from "../../libs/Drawer/Drawer";
import { Burger, Image } from "@mantine/core";
import { useNavigator } from "../../../pages-lib/navigation/useNavigator";

interface SideBarProps {
  widthInRem?: number;
  menuItems?: React.ReactNode;
  responsiveFixedSideNavOnLgScreen?: boolean;
}

export const SideBar: React.FC<SideBarProps> = ({
  widthInRem = 19.5,
  menuItems,
  responsiveFixedSideNavOnLgScreen = true,
}) => {
  const [mobileNavOn, mobileNavOnToggle] = useBoolean(false);

  const { navigate } = useNavigator();

  return (
    <>
      <div className=" lg:hidden ">
        {" "}
        <div className="py-2">
          <Burger
            opened={mobileNavOn}
            onClick={() => mobileNavOnToggle()}
            title={""}
            color="var(--primary-accent)"
          />
        </div>
        {
          <Drawer
            show={mobileNavOn}
            onClose={mobileNavOnToggle}
            title={
              <Image
                src={"/logo.png"}
                onClick={() => navigate("home")}
                className="cursor-pointer"
                alt={"logo"}
              />
            }
          >
            <SideMenu show={mobileNavOn}>{menuItems}</SideMenu>
          </Drawer>
        }
      </div>
      {responsiveFixedSideNavOnLgScreen && (
        <div className="hidden lg:flex absolute shadow-md">
          {" "}
          <SideMenu className={`w-[${widthInRem}rem] w-[19.5rem]`} show={true}>
            {" "}
            {menuItems}
          </SideMenu>
        </div>
      )}
    </>
  );
};
