import { PROD } from "../config/appState";

export const APP_INFO = {
  ORG_FULL_NAME: "Zoni Language Centers, Inc",
  ORG_WEBSITE_URL: "https://zoni.edu",
  ORG_SHORT_NAME: "Zoni",
  ORG_FACEBOOK_URL: "https://www.facebook.com/zonilanguage/",
  ORG_INSTAGRAM_URL: "https://www.instagram.com/zonilanguage/?hl=en",
  APP_NAME: "Zoni Store",
  APP_ID: "zoni_store",
  APP_BASE_URL: PROD ? "https://store.zoni.edu" : "https://test.store.zoni.edu",
  SUPPORT_EMAIL: "zonistore@zoni.edu",
};

export const COPYRIGHT_TEXT = `
Copyright © ${new Date().getFullYear()} ${
  APP_INFO.ORG_FULL_NAME
}. All rights reserved.
`;
