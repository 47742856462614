import React from "react";
import Head from "next/head";
import { AnimatePresence, motion } from "framer-motion";
import { Footer } from "../components/shared/Footer/Footer";
import { NavBar } from "../components/shared/NavBar/NavBar";
import { log } from "../utils/logger";
interface IPageShellProps {
  title?: string;
  keywords?: Array<string>;
  children?: any;
  subNav?: React.ReactNode;
  NavigationBar?: React.ReactNode;
}

const variants = {
  hidden: { opacity: 0, x: -100, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};

export const PageShell: React.FC<IPageShellProps> = ({
  title = "Welcome to Zoni Store!",
  subNav,
  children,
  NavigationBar,
  keywords = [],
}) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Zoni Online Store." />
        <link rel="icon" href="/favicon.ico" />
        <meta name="keywords" content={keywords.join(", ")} />
      </Head>
      <div>
        {NavigationBar ? NavigationBar : <NavBar></NavBar>}
        {subNav && subNav}
        <AnimatePresence>
          <div className="">
            <motion.main
              variants={variants} // Pass the variant object into Framer Motion
              initial="hidden" // Set the initial state to variants.hidden
              animate="enter" // Animated state to variants.enter
              exit="exit" // Exit state (used later) to variants.exit
              transition={{ type: "linear" }} // Set the transition to linear
              className=" min-h-screen"
            >
              {" "}
              {children}
            </motion.main>
          </div>
        </AnimatePresence>
        <Footer />
      </div>
    </div>
  );
};
