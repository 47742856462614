import { Image } from "@mantine/core";
import React from "react";
import { IStoreProduct } from "../../../models/product/types";
import { RatingStars } from "../../libs/Rating/RatingStars";
import { PriceTagBouncing } from "./Price/PriceTagBouncing";
import { useProductImages } from "./useProductImages";
import { useProductRatings } from "./useProductRatings";

interface IProductHorizontalProps extends IStoreProduct {
  onProductClick: () => void;
}

export const ProductHorizontal: React.FC<IProductHorizontalProps> = ({
  onProductClick,
  ratings,
  files,
  price,
  title,
  currentPrice,
}) => {
  const { primaryImage } = useProductImages();

  const { productRating } = useProductRatings(ratings);
  return (
    <div
      onClick={() => onProductClick()}
      className="flex group rounded-lg   hover:bg-primary-fg pb-10 overflow-hidden space-y-2 cursor-pointer"
    >
      <div className="  p-6 flex justify-center relative">
        <div className=" w-16 ">
          <Image radius="md" src={primaryImage(files)} alt={title} />
        </div>

        <PriceTagBouncing price={`${currentPrice}`} />
      </div>
      <div className="px-sm space-y-2">
        {" "}
        <div className="text-left  ">{title}</div>
        <RatingStars rating={productRating} numberOfRatings={ratings?.length} />
      </div>
    </div>
  );
};
