import { Image } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";

interface IProfilePicProps {
  url?: string;
  title: string;
  fallbackUrl?: string;
}

export const ProfilePic: React.FC<IProfilePicProps> = ({
  url,
  title,
  fallbackUrl,
}) => {
  // const [imgSrc, setImgSrc] = useState(url ?? "/profile_picture_default.svg");

  // useEffect(() => {
  //   if (!!!url) {
  //     return;
  //   }
  //   setImgSrc(url);
  // }, [url]);

  // if (!!!url) {
  //   return <FaRegUser className="text-2xl text-primary-accent" />;
  // }

  return (
    <Image
      src={url}
      alt={"p"}
      title={title}
      withPlaceholder={true}
      classNames={{ placeholder: "bg-white border-none" }}
      placeholder={<FaRegUser className="text-2xl text-primary-accent" />}
    />
  );
};
