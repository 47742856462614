import React from "react";
import { Divider, Drawer as MDrawer, useMantineTheme } from "@mantine/core";

interface IDrawerProps {
  show: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export const Drawer: React.FC<IDrawerProps> = ({
  show,
  onClose,
  title,
  children,
}) => {
  return (
    <MDrawer
      opened={show}
      onClose={() => onClose()}
      title={title}
      size="lg"
      closeOnEscape
      classNames={{
        overlay: "backdrop bg-transparent",
        closeButton: "text-4xl font-semibold rounded-full w-10 h-10 flex",
        root: "relative",
        header: "py-4 pl-2",
      }}
      overlayColor={"#fff"}
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      {children}
    </MDrawer>
  );
};
