import React from "react";
import { HiOutlineShoppingBag, HiShoppingBag } from "react-icons/hi";

import { BadgeNotification } from "../../libs/Badge/BadgeNotification";

interface ICartIconProps {
  active?: boolean;
  itemsInCart?: number;
}
export const CartIcon: React.FC<ICartIconProps> = ({ active, itemsInCart }) => {
  return (
    <div className="relative">
      {active ? (
        <HiShoppingBag className="text-3xl text-primary-accent" />
      ) : (
        <HiOutlineShoppingBag className="text-3xl  text-primary-accent" />
      )}
      {!!itemsInCart && (
        <div className="absolute top-0 right-0  ">
          <BadgeNotification notificationCount={itemsInCart} />
        </div>
      )}
    </div>
  );
};
