import React from "react";

interface IPriceTagBouncingProps {
  price: string;
  discountedPrice?: string;
}

export const PriceTagBouncing: React.FC<IPriceTagBouncingProps> = ({
  price,
  discountedPrice,
}) => {
  return (
    <div className="absolute left-2 bottom-0 bg-white p-2 rounded-sm group-hover:animate-bounce flex space-x-2">
      <div className={`${!!discountedPrice ? "line-through" : ""}`}>
        {" "}
        {`$ ${price}`}
      </div>

      {!!discountedPrice && (
        <div className="text-primary-error">{discountedPrice}</div>
      )}
    </div>
  );
};
