import React from "react";
import { IconWrapper } from "../../libs/Icon/IconWrapper";
import { ToolTip } from "../../libs/ToolTip/ToolTip";

interface INavBarMenuItemProps {
  name: string;
  isSelected: boolean;
  onSelect: () => void;
  icon: React.ReactNode;
  children?: React.ReactNode;
  showName?: boolean;
}

export const NavBarMenuItem: React.FC<INavBarMenuItemProps> = ({
  name,
  icon,
  onSelect,
  isSelected,
  showName = false,
}) => {
  return (
    <ToolTip label={name}>
      <button className="flex" onClick={onSelect}>
        <div className="flex text-primary-accent items-center space-x-1">
          {" "}
          <div>{icon}</div>
          {showName && <div className="text-xs font-medium">{name}</div>}
        </div>
      </button>
    </ToolTip>
  );
};
