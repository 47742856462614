import { Popover } from "@mantine/core";
import { useBooleanToggle } from "@mantine/hooks";
import React, { useMemo } from "react";
import { RiStarFill, RiStarHalfFill, RiStarLine } from "react-icons/ri";
import { PopOver } from "../PopOver/PopOver";

interface IRatingStarsProps {
  rating?: number;
  numberOfRatings?: number;
  onNumberOfRatingsClick?: () => void;
  size?: "sm" | "lg" | "xl";
}

export const RatingStars: React.FC<IRatingStarsProps> = ({
  rating,
  numberOfRatings,
  onNumberOfRatingsClick,
  size = "sm",
}) => {
  const sanitizedRating = useMemo(() => {
    if (!rating) {
      return;
    }
    let _rating = rating;
    if (_rating > 5) {
      _rating = 5;
    }
    return _rating;
  }, [rating]);

  const numberOfFullStars = useMemo(() => {
    if (!sanitizedRating) {
      return 0;
    }

    return Math.floor(sanitizedRating);
  }, [sanitizedRating]);

  const numberOfHalfStars = useMemo(() => {
    if (!sanitizedRating) {
      return 0;
    }

    const diff = Math.abs(sanitizedRating - Math.floor(sanitizedRating));
    if (diff > 0) {
      return 1;
    }
    return 0;
  }, [sanitizedRating]);

  const numberOfEmptyStars = useMemo(() => {
    return 5 - (numberOfHalfStars + numberOfFullStars);
  }, [numberOfFullStars, numberOfHalfStars]);

  return (
    <div className="flex items-center space-x-2 ">
      <div
        className={`flex ${
          size === "lg" ? "text-lg" : size === "xl" ? "text-xl" : ""
        }`}
      >
        {" "}
        {[...Array(numberOfFullStars)].map((_, i) => (
          <RiStarFill key={i} className="text-primary-warning" />
        ))}
        {[...Array(numberOfHalfStars)].map((_, i) => (
          <RiStarHalfFill key={i} className="text-primary-warning" />
        ))}
        {[...Array(numberOfEmptyStars)].map((_, i) => (
          <RiStarFill key={i} className="text-slate-200 " />
        ))}
      </div>

      {typeof numberOfRatings === "number" ? (
        <div
          className="link-primary"
          onClick={() => onNumberOfRatingsClick?.()}
        >{`(${numberOfRatings})`}</div>
      ) : null}
    </div>
  );
};
