import { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal as MantineModal } from "@mantine/core";

interface IModalPlainProps {
  show: boolean;
  toggleShow: () => void;
  onDone?: (optionalParams?: any) => void;
  onClosed?: () => void;
  ActionButton?: React.ReactNode;
  Body: React.ReactNode;
  size?: "small" | "medium" | "large" | "full";
  showCloseButton?: boolean;
}

export const ModalPlain: React.FC<IModalPlainProps> = ({
  show,
  toggleShow,
  onDone,
  Body,
  ActionButton,
  size = "small",
  onClosed,
  showCloseButton = true,
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  function closeModal() {
    onClosed?.();
    toggleShow?.();
  }

  // Mapping the size prop to Mantine sizes
  const modalSize = size === "full" ? "100%" : size === "large" ? "80%" : "md";

  return (
    <MantineModal
      opened={show}
      onClose={closeModal}
      size={modalSize}
      centered
      overlayOpacity={0.5}
      overlayBlur={3}
      zIndex={201} // Adjusting zIndex
      withCloseButton={false} // We are handling close button manually
      closeOnClickOutside
    >
      <div className="flex flex-col gap-4 relative">
        {/* Close Button */}
        {showCloseButton && (
          <button
            className="absolute -right-4 -top-4 hover-primary bg-white rounded-full p-1 focus:outline-none"
            ref={cancelButtonRef}
            onClick={closeModal}
          >
            <AiOutlineClose className="text-primary-error text-xl md:text-3xl font-semibold" />
          </button>
        )}

        {/* Body */}
        <div>{Body}</div>

        {/* Action Button */}
        <div
          onClick={() => {
            closeModal();
            onDone?.();
          }}
          className="mt-4 flex justify-center"
        >
          {ActionButton}
        </div>
      </div>
    </MantineModal>
  );
};
