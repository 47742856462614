import { ScrollArea, TextInput } from "@mantine/core";
import { useBooleanToggle } from "@mantine/hooks";
import React, { useCallback, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useQuery } from "react-query";
import { productsGet } from "../../../api/productsGet";
import { IStoreProduct } from "../../../models/product/types";
import { useNavigator } from "../../../pages-lib/navigation/useNavigator";
import { ProductHorizontal } from "../../app/Product/ProductHorizontal";
import { ModalPlain } from "../../libs/Modal/ModalPlain";

interface IProductSearchBarProps {}

export const ProductSearchBar: React.FC<IProductSearchBarProps> = ({}) => {
  const activeBranchId = useMemo(() => {
    return 1;
  }, []);
  const {
    data: products,
    isLoading: productsLoading,
    error: productsError,
  } = useQuery(["products", { storeBranchId: activeBranchId }], () =>
    productsGet({ storeBranchId: activeBranchId })
  );
  const [searchString, setSearchString] = useState<string>();

  const handleSearchStringChange = useCallback((newString?: string) => {
    setSearchString(newString);
  }, []);

  const [searchModal, searchModalToggle] = useBooleanToggle();

  const { navigateToProductDetail } = useNavigator();

  const searchResult = useMemo(() => {
    return products?.filter((p) => {
      const stringifiedProduct = JSON.stringify(p);
      if (
        stringifiedProduct
          .toLocaleLowerCase()
          .includes(searchString?.trim().toLowerCase() ?? "")
      ) {
        return true;
      }
      return false;
    });
  }, [products, searchString]);

  return (
    <>
      <TextInput
        placeholder="Search Products..."
        icon={<AiOutlineSearch className="text-xl" />}
        onClick={() => searchModalToggle()}
        value={searchString}
        size={"md"}
        className={"min-w-[20rem]"}
        onChange={(n) => handleSearchStringChange(n.target.value)}
      />

      <ModalPlain
        show={searchModal}
        toggleShow={searchModalToggle}
        showCloseButton={false}
        Body={
          <div className="space-y-4">
            <TextInput
              placeholder="Search Zoni Products..."
              icon={<AiOutlineSearch className="text-3xl" />}
              value={searchString}
              size="lg"
              onChange={(n) => handleSearchStringChange(n.target.value)}
            />
            <ScrollArea style={{ height: 700 }}>
              {searchResult?.map((r) => (
                <ProductHorizontal
                  key={r.storeProductId}
                  {...r}
                  onProductClick={() => navigateToProductDetail(r.productId)}
                />
              ))}
            </ScrollArea>
          </div>
        }
      />
    </>
  );
};
