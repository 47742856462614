import { PRODUCT_FILES_BASE_URL } from "../constants/links";

interface IGetProductFilesURLParams {
  directory?: string;
  fileNameWithExtension?: string;
}

export const getProductFilesURL = ({
  directory,
  fileNameWithExtension,
}: IGetProductFilesURLParams) => {
  if (!directory) {
    return undefined;
  }
  if (!fileNameWithExtension) {
    return undefined;
  }
  return `${PRODUCT_FILES_BASE_URL}/${directory}/${fileNameWithExtension}`;
};
