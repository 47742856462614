export type IAppRoutesName =
  | "home"
  | "findMyStore"
  | "aboutUs"
  | "faqs"
  | "returnPolicy"
  | "return"
  | "trackOrder"
  | "returnMyOrders"
  | "termsAndConditions"
  | "privacyPolicy"
  | "cart"
  | "product"
  | "productRate"
  | "checkout"
  | "checkoutComplete"
  | "adminHome"
  | "adminVendors"
  | "adminShipment"
  | "adminShipmentCreate"
  | "adminShipmentEdit"
  | "adminInventory"
  | "adminProductListings"
  | "adminReplenishHistory"
  | "adminReplenishInventory"
  | "adminSendInventory"
  | "adminSendInventoryHistory"
  | "adminReceiveInventory"
  | "adminProductsCreate"
  | "adminProductsEdit"
  | "adminSalesOnStore"
  | "adminSalesReport"
  | "adminOrders"
  | "adminOrderRefund";

export type IAppRoutesValue = {
  location: string;
  id: string;
  queries?: Array<string>;
};

export const APP_ROUTES: Record<IAppRoutesName, IAppRoutesValue> = {
  home: {
    location: "/",
    id: "home",
  },
  findMyStore: {
    location: "/findMyStore",
    id: "findMyStore",
  },
  returnPolicy: {
    location: "/returnPolicy",
    id: "returnPolicy",
  },
  return: {
    location: "/return",
    id: "return",
  },
  returnMyOrders: {
    location: "/return/returnMyOrders",
    id: "returnMyOrders",
  },
  trackOrder: {
    location: "/order/track",
    id: "trackOrder",
    queries: ["orderId"],
  },
  termsAndConditions: {
    location: "/termsAndConditions",
    id: "termsAndConditions",
  },
  aboutUs: {
    location: "/aboutUs",
    id: "aboutUs",
  },
  faqs: {
    location: "/faqs",
    id: "faqs",
  },
  privacyPolicy: {
    location: "/privacyPolicy",
    id: "privacyPolicy",
  },
  cart: {
    location: "/cart",
    id: "cart",
  },
  product: {
    location: "/product",
    id: "product",
  },
  productRate: {
    location: "/product/rate",
    id: "productRate",
    queries: ["productId"],
  },
  checkout: {
    location: "/checkout",
    id: "checkout",
  },
  checkoutComplete: {
    location: "/checkout/orderComplete",
    id: "checkoutComplete",
    queries: ["confirmation"],
  },
  adminHome: {
    location: "/admin/home",
    id: "adminHome",
  },
  adminShipment: {
    location: "/admin/shipment",
    id: "adminShipment",
  },
  adminShipmentCreate: {
    location: "/admin/shipment/create",
    id: "adminShipmentCreate",
  },
  adminShipmentEdit: {
    location: "/admin/shipment/edit",
    id: "adminShipmentEdit",
    queries: ["shipmentId", "step"],
  },
  adminVendors: {
    location: "/admin/vendors",
    id: "adminVendors",
  },
  adminProductListings: {
    location: "/admin/listings",
    id: "adminProductListings",
  },
  adminProductsCreate: {
    location: "/admin/listings/create",
    id: "adminProductsCreate",
  },
  adminProductsEdit: {
    location: "/admin/listings/edit",
    id: "adminProductsEdit",
    queries: ["productId"],
  },
  adminInventory: {
    location: "/admin/inventory",
    id: "adminInventory",
  },
  adminReplenishInventory: {
    location: "/admin/inventory/replenish/create",
    id: "adminReplenishInventory",
  },
  adminReplenishHistory: {
    location: "/admin/inventory/replenish",
    id: "adminReplenishHistory",
  },
  adminReceiveInventory: {
    location: "/admin/inventory/receive",
    id: "adminReceiveInventory",
  },
  adminSendInventory: {
    location: "/admin/inventory/send/create",
    id: "adminSendInventory",
  },
  adminSendInventoryHistory: {
    location: "/admin/inventory/send",
    id: "adminSendInventoryHistory",
  },
  adminSalesOnStore: {
    location: "/admin/sales/on-store",
    id: "adminSalesOnStore",
  },
  adminSalesReport: {
    location: "/admin/sales/report",
    id: "adminSalesReport",
  },
  adminOrders: {
    location: "/admin/orders",
    id: "adminOrders",
  },
  adminOrderRefund: {
    location: "/admin/orders/refund",
    id: "adminOrderRefund",
    queries: ["orderId"],
  },
};
