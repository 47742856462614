import React, { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { storeBranchesGet } from "../api/storeBranchesGet";

export const useStoreBranches = () => {
  const { data: storeBranches, isLoading: storeBranchesIsLoading } = useQuery(
    ["storeBranches"],
    storeBranchesGet,
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const getStoreBranchByStoreBranchId = useCallback(
    (storeBranchId: number) => {
      return storeBranches?.find((i) => i.storeBranchId === storeBranchId);
    },
    [storeBranches]
  );

  const storeBranchesSelectData = useMemo(() => {
    return storeBranches?.map((s) => {
      return {
        value: s.storeBranchId.toString(),
        label: s.name,
      };
    });
  }, [storeBranches]);

  return {
    storeBranches,
    storeBranchesSelectData,
    storeBranchesIsLoading,
    getStoreBranchByStoreBranchId,
  };
};
