import Image from "next/image";
import Link from "next/link";
import React from "react";
import { NavBarMenuItem } from "./NavBarMenuItem";
import zoniLogoWithText from "/public/logoWithText.svg";
import zoniLogo from "/public/logo.png";
import { CartIcon } from "../../app/Cart/CartIcon";
import { useCartContext } from "../../app/Cart/useCart";
import { useNavigator } from "../../../pages-lib/navigation/useNavigator";
import { UserProfile } from "../UserProfile/UserProfile";
import { useUser, useUserBasedOnMS } from "../../../contexts/Msal/useMsalUser";
import { ProductSearchBar } from "../Search/ProductSearchBar";
import { SideBar } from "../SideBar/SideBar";
import { SideMenuItemsUser } from "../SideBar/SideMenuItemsUser";
import { useMediaQuery } from "@mantine/hooks";
import { BsHeadset } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { openNewTab } from "../../../utils/openNewTab";
import { ZONI_PORTAL_PAGES } from "../../../constants/links";
interface INavBarProps {
  children?: React.ReactNode;
}

export const NavBar: React.FC<INavBarProps> = ({ children }) => {
  const cartContext = useCartContext();

  const { navigate, isRouteActive } = useNavigator();

  // const { onLogin, onLogOut, msUser } = useMsalUser();
  const context = useUserBasedOnMS();
  const { dbUser, msUser } = useUser();

  const screenBiggerThanSmall = useMediaQuery("(min-width: 1100px)");

  return (
    <nav className="sticky top-0 z-40 w-full shadow-primary  px-sm backdrop bg-white">
      <div className="max-w-primary flex justify-between items-center  h-full min-h-[4rem] md:min-h-[5rem] space-x-2">
        <div className="flex space-x-2 items-center">
          <SideBar
            responsiveFixedSideNavOnLgScreen={false}
            menuItems={<SideMenuItemsUser />}
          />

          <Link href={"/"} passHref>
            <a className="">
              <div className="">
                {" "}
                <Image
                  src={screenBiggerThanSmall ? zoniLogoWithText : zoniLogo}
                  alt={"logo"}
                />
              </div>
            </a>
          </Link>
        </div>
        <div className="flex-0 flex space-x-6 lg:space-x-12 items-center">
          {children}
          {
            <div className="hidden lg:flex pr-20">
              {" "}
              <ProductSearchBar />
            </div>
          }
          <div className="hidden md:flex space-x-2 md:space-x-4 items-center">
            <NavBarMenuItem
              name="Support"
              isSelected={false}
              onSelect={() =>
                openNewTab(
                  ZONI_PORTAL_PAGES.CREATE_TICKET({
                    email: msUser?.username ?? "",
                    firstName: dbUser?.firstName ?? "",
                    lastName: dbUser?.lastName ?? "",
                    phone: dbUser?.phone ?? "",
                  })
                )
              }
              icon={<BsHeadset className="text-xl" />}
              showName
            />

            <NavBarMenuItem
              name="Find a store"
              isSelected={false}
              onSelect={() => navigate("findMyStore")}
              icon={<GoLocation className="text-xl" />}
              showName
            />
          </div>
          <div className="flex items-center space-x-2">
            <NavBarMenuItem
              name="Cart"
              isSelected={isRouteActive("cart")}
              onSelect={() => navigate("cart")}
              icon={
                <CartIcon
                  active={isRouteActive("cart")}
                  itemsInCart={cartContext?.cart?.length}
                />
              }
            />
            {!!!context?.msUser ? (
              <button
                className="button-primary max-w-[7ch]"
                onClick={() => context?.onLogin()}
              >
                Login
              </button>
            ) : (
              <UserProfile
                email={msUser?.username}
                firstName={dbUser?.firstName}
                lastName={dbUser?.lastName}
                profileUrl={dbUser?.profilePicUrl}
                onLogoutClick={() => context?.onLogOut()}
                role={dbUser?.role}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};
