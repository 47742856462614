import { Tooltip } from "@mantine/core";
import React from "react";

interface IToolTipProps {
  label?: string;
  children?: React.ReactNode;
  className?: string;
}

export const ToolTip: React.FC<IToolTipProps> = ({
  children,
  label,
  className = "",
}) => {
  return (
    <Tooltip
      color={"blue"}
      label={label}
      transitionDuration={300}
      transitionTimingFunction="ease"
      withArrow
      disabled={!!!label}
      classNames={{ body: "bg-gray-400 text-xs", arrow: "bg-gray-400" }}
      className={className}
    >
      {children}
    </Tooltip>
  );
};
