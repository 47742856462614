import { APP_STATE } from "../config/appState";
import { envVars } from "../config/envVars";
import { APP_INFO } from "./appInfo";

export const PROFILE_PIC_BASE_URL =
  APP_STATE === "Development"
    ? "https://zoni.edu/zoni-portal-files"
    : "https://services.portal.zoni.edu/zoni-portal-files";

export const FILE_UPLOAD_URL = {
  PRODUCT_FILE_UPLOAD_LINK:
    "https://services.portal.zoni.edu/zoni-store-files/UTF.php",
};

export const PRODUCT_FILES_BASE_URL =
  "https://services.portal.zoni.edu/zoni-store-files/uploads";

export const ZONI_EDU_LINK = "https://www.zoni.edu";

export const ZONI_EDU_PAGES = {
  HOME_PAGE: `${ZONI_EDU_LINK}`,
  REFUND_POLICY: `${ZONI_EDU_LINK}/refund_policy.html`,
};

export const ZONI_SYS_LINK =
  APP_STATE === "Development"
    ? "https://testzonisys.zoni.edu"
    : "https://zonisys.zoni.edu";

export const ZONI_PORTAL_LINK =
  APP_STATE === "Development"
    ? "https://testportal.zoni.edu"
    : "https://portal.zoni.edu";

export const ZONI_SYS_PAGES = {
  STUDENT_PROFILE: ({ stid }: { stid: string }) => {
    return `${ZONI_SYS_LINK}/student/course/?stid=${stid}&acc=sch,pymt,ext#pymt`;
  },
  EMPLOYEE_PROFILE: ({ userId }: { userId: string }) => {
    return `${ZONI_SYS_LINK}/employee/info/?userid=${userId}`;
  },
};

export const ZONI_PORTAL_PAGES = {
  CREATE_TICKET: ({
    firstName,
    lastName,
    phone,
    source = APP_INFO.APP_ID,
    email,
  }: {
    firstName: string;
    lastName: string;
    phone: string;
    source?: string;
    email: string;
  }) =>
    `${ZONI_PORTAL_LINK}/support?firstName=${firstName}&lastName=${lastName}&userEmail=${email}&source=${source}&phone=${phone}`,
};

export const ZONI_LIVE_PAGES = {
  CONTACT_US: `https://zonilive.com/contact-us`,
};

const ZONI_PAYMENT_SRVICE_BASE_URL = envVars("PAYMENT_SERVER_URL");

export const ZONI_PAYMENT_SERVICE_API = {
  SEND_PAYMENT: `${ZONI_PAYMENT_SRVICE_BASE_URL}/payStore`,
  VOID_PAYMENT: `${ZONI_PAYMENT_SRVICE_BASE_URL}/voidCCTrans`,
  REFUND_TRANS: `${ZONI_PAYMENT_SRVICE_BASE_URL}/refundTrans`,
};
